'use client'
import Button from '@/components/core/Button'
import Dropdown from '@/components/core/Dropdown'
import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'
import ImageWithFallback from '@/components/organisms/NavHeaderLoggedIn/sub/ImageWithFallback'
import useProfile from '@/components/providers/profile/useProfile'
import type { DivProps } from '@/types/common'
import clsx from 'clsx'
import PrivateCommunities from '../PrivateCommunities'
import UserPoints from '../UserPoints'
import styles from './UserDropdown.module.scss'

type UserDropdownProps = DivProps

const UserDropdown = ({ className, ...props }: UserDropdownProps) => {
  const { profile, logout } = useProfile()
  const { contributionPoints, pic, displayname, email, slug, hasDemoAccess, groupsMembership } = profile
  return (
    <Dropdown
      {...props}
      className={clsx(className, styles.user_dropdown)}
      trigger={
        <ImageWithFallback className={styles.user_profile_image} profileImage={pic} alt={displayname} loading="eager" />
      }
      openRight
    >
      <Dropdown.Item>
        <div className={styles.user_data}>
          <div className={styles.user_username_and_email}>
            <Typography variant="h5" as="span" className={styles.user_name}>
              <b>{displayname}</b>
            </Typography>
            <Typography variant="h7" as="span" className={styles.user_email}>
              {email}
            </Typography>
          </div>
          <Link prefetch={false} href={'/redeem'}>
            <UserPoints points={contributionPoints} />
          </Link>
        </div>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link prefetch={false} href={`/profile/${slug}`}>
          <Typography variant="h6" as="span">
            <b>My Profile</b>
          </Typography>
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link prefetch={false} href={'/my-content'}>
          <Typography variant="h6" as="span">
            <b>My Content</b>
          </Typography>
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link prefetch={false} href={'/redeem'}>
          <Typography variant="h6" as="span">
            <b>My Rewards</b>
          </Typography>
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link prefetch={false} href={'/connections'}>
          <Typography variant="h6" as="span">
            <b>My Connections</b>
          </Typography>
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link prefetch={false} href={'/settings'}>
          <Typography variant="h6" as="span">
            <b>My Email Preferences</b>
          </Typography>
        </Link>
      </Dropdown.Item>
      <Dropdown.Item>
        <Link prefetch={false} href={'/edit-profile'}>
          <Typography variant="h6" as="span">
            <b>Edit Profile</b>
          </Typography>
        </Link>
      </Dropdown.Item>
      {groupsMembership.length > 0 && (
        <Dropdown.Item className={styles.private_communities_menu_item}>
          <PrivateCommunities />
        </Dropdown.Item>
      )}

      {hasDemoAccess && (
        <Dropdown.Item className={styles.admin}>
          <Typography variant="disclaimer" className={styles.admin_tools}>
            Admin tools
          </Typography>
          <Link prefetch={false} className={styles.demo_access} href={'/admin-tools/demo-access'}>
            <Typography variant="h6" as="span">
              <b>Demo Access</b>
            </Typography>
          </Link>
        </Dropdown.Item>
      )}
      <Dropdown.Item>
        <Button variant="ghost" onClick={logout}>
          <Typography variant="h6" as="span">
            <b>Sign Out</b>
          </Typography>
        </Button>
      </Dropdown.Item>
    </Dropdown>
  )
}

export default UserDropdown
