'use client'
import Link from '@/components/core/Link'
import NotificationIcon from '@/components/icons/NotificationIcon'

import BugsnagService from '@/services/bugsnag/client'

import Typography from '@/components/core/Typography'

import { useRedirectToAuthUrl } from '@/components/hooks/useRedirectToAuthUrl'
import { getNotifications } from '@/services/profile/profileActions'
import Bugsnag from '@bugsnag/js'
import { useEffect, useState } from 'react'
import styles from './Notifications.module.scss'

const Notifications = () => {
  const ONE_MINUTE = 60 * 1000

  const [notifications, setNotifications] = useState(0)

  const { unauthorized } = useRedirectToAuthUrl()

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    async function fetchNotifications() {
      try {
        const { data, error } = await getNotifications()
        if (error?.status === 401) {
          await unauthorized()
          return
        }
        if (data && !error) {
          setNotifications(data.new_message_count)
          timeout = setTimeout(fetchNotifications, 2 * ONE_MINUTE)
        }
      } catch (e) {
        if (e instanceof Error) {
          BugsnagService.bugsnagStart()
          Bugsnag.notify(`Error fetching notifications: ${e.message}`, (event) => {
            event.unhandled = false
            event.severity = 'error'
          })
        }
      }
    }

    fetchNotifications()

    return () => clearTimeout(timeout)
  }, [])

  return (
    <span className={styles.user_notifications}>
      <Link prefetch={false} href="/notifications">
        <NotificationIcon size={32} />
        {notifications > 0 && (
          <Typography className={styles.badge} as="span" variant="disclaimer">
            <b>{notifications}</b>
          </Typography>
        )}
      </Link>
    </span>
  )
}

export default Notifications
