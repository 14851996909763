import type { IconComponentType } from '../types'

const ArrowOutIcon = ({ size, className = '' }: IconComponentType) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>arrow out icon</title>
    <path
      d="M6.29423 17.6442L5.25003 16.6L15.0904 6.74995H6.14426V5.25H17.6442V16.75H16.1443V7.80378L6.29423 17.6442Z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowOutIcon
