import Button from '@/components/core/Button'
import CloseIcon from '@/components/icons/CloseIcon'
import { useEventContext } from '@/components/providers/eventContext'
import type { DivProps } from '@/types/common'
import { cleanHtmlTags } from '@/util/strings'
import { useRef, useState } from 'react'
import styles from './InputDropdown.module.scss'

const InputDropdown = ({ children }: DivProps) => {
  const [searchInput, setSearchInput] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { getEventProps } = useEventContext({
    name: 'topics_page_source_clicked_search_dropdown',
  })

  return (
    <>
      <input
        name="text"
        type="text"
        placeholder="Search for insights, advice, and more"
        aria-label="Search query"
        required
        onChange={(e) => setSearchInput(cleanHtmlTags(e.target.value))}
        value={searchInput}
        className={styles.search_input}
        autoComplete="off"
        ref={inputRef}
      />
      <Button
        variant="ghost"
        onClick={() => {
          inputRef.current?.focus()
          setSearchInput('')
        }}
        type="button"
      >
        <CloseIcon size={10} />
      </Button>
      <div title="dropdown" {...getEventProps()} className={styles.dropdown_container}>
        {children}
      </div>
    </>
  )
}

export default InputDropdown
