'use client'
import Button from '@/components/core/Button'
import Dropdown from '@/components/core/Dropdown/Dropdown'
import Typography from '@/components/core/Typography'
import CreateIcon from '@/components/icons/CreateIcon'
import { useModal } from '@/components/organisms/Modal'
import ModalAwaitingForApproval from '@/components/organisms/ModalAwaitingForApproval'
import { CONTENT_STATE_UNVERIFIED, CONTENT_STATE_VERIFIED } from '@/constants/gpc'
import { POLL, POST } from '@/constants/postInfo'
import type { DivProps } from '@/types/common'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import styles from './UserActions.module.scss'
/* istanbul ignore next */
const ModalUserActions = dynamic(() => import('../ModalUserActions'))

type FormType = 'POST' | 'POLL'

const UserActions = ({ ...props }: DivProps) => {
  const [formType, setFormType] = useState<FormType>(POST)
  const router = useRouter()

  const [showModalAwaitingForApproval, hideModalAwaitingForApproval] = useModal(
    () => <ModalAwaitingForApproval keyword={formType.toLowerCase()} onCloseHandler={hideModalAwaitingForApproval} />,
    [formType]
  )

  const [showModalUserActions, hideModalUserActions] = useModal(
    () => (
      <ModalUserActions
        onCloseHandler={(entityType = POST, contentState?: number, slug?: string) => {
          hideModalUserActions()
          if (contentState === CONTENT_STATE_VERIFIED) {
            router.push(`/${entityType.toLocaleLowerCase()}/${slug}`)
          }
          if (contentState === CONTENT_STATE_UNVERIFIED) {
            setFormType(entityType)
            showModalAwaitingForApproval()
          }
        }}
        defaultFormType={formType}
      />
    ),
    [formType]
  )
  const handler = (formType: FormType) => () => {
    setFormType(formType)
    showModalUserActions()
  }
  return (
    <Dropdown {...props} className={styles.user_actions_dropdown} trigger={<CreateIcon size={32} />} openRight>
      <Dropdown.Item>
        <Typography variant="h6" as="span">
          <Button variant="ghost" onClick={handler(POLL)} className={styles.action_btn}>
            <b>Create a Poll</b>
          </Button>
        </Typography>
      </Dropdown.Item>
      <Dropdown.Item>
        <Typography variant="h6" as="span">
          <Button variant="ghost" onClick={handler(POST)} className={styles.action_btn}>
            <b>Ask a Question</b>
          </Button>
        </Typography>
      </Dropdown.Item>
    </Dropdown>
  )
}

export default UserActions
